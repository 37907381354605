import React from "react";
import { Link } from "gatsby";
import BusinessCheques_Hero from "../../../assets/img/business-cheques-hero.jpg";
import high_security_cheques from "../../../assets/img/high-security-cheques.jpg";
import computer_cheques from "../../../assets/img/computer-cheques.jpg";
import manual_cheques from "../../../assets/img/manual-cheques.jpg";
import personal_cheques from "../../../assets/img/personal-cheques.jpg";
import custom_cheques from "../../../assets/img/CustomChecks_Thumbnail.jpg";
import ezshield_cheques from "../../../assets/img/ezshield-cheques.jpg";
import cash_management from "../../../assets/img/CashManagement_Thumbnail.jpg";

import styles from "../style/styles.module.css";

export default function BusinessCheques() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Business Cheques</h1>
          <h2>Check with DFS first.</h2>
          <p className={styles.productParag}>
            Your best source for business cheques.
          </p>
          <img
            className={styles.hero}
            src={BusinessCheques_Hero}
            alt="Business cheques Image"
          />

          <p className={styles.productMargin}>
            Our extensive selection of business cheques makes it easy for
            resellers to find products that meet your customers payment and cash
            management needs.
          </p>
          <hr className={styles.productHr} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Product Category Highlights</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img
                src={high_security_cheques}
                alt="High security cheques Image"
              ></img>
              <Link to="/products/business-cheques/high-security-cheques/">
                High Security Cheques
              </Link>
              <ul>
                <li>
                  Offers customers extra security that can prevent a crippling
                  loss from Cheque fraud
                </li>
                <li>Provides peace of mind for pennies more per Cheque</li>
              </ul>
            </div>
            <div className={styles.card}>
              <img src={computer_cheques} alt="Laser cheques Image"></img>
              <Link to="/products/business-cheques/laser-cheques/">
                Laser Cheques
              </Link>
              <ul>
                <li>Laser and continuous</li>
                <li>Compatible with more than 2,000 software packages</li>
                <li>Custom options for niche software</li>
              </ul>
            </div>
            <div className={styles.card}>
              <img src={manual_cheques} alt="Manual cheques Image"></img>
              <Link to="/products/business-cheques/manual-cheques/">
                Manual Cheques
              </Link>
              <ul>
                <li>One-to-a-Page Cheques</li>
                <li>Two-to-a-Page Cheques</li>
              </ul>
            </div>
            <div className={styles.card}>
              <img src={personal_cheques} alt="Personal cheques Image"></img>
              <Link to="/products/business-cheques/personal-cheques/">
                Personal Cheques
              </Link>
              <ul>
                <li>Two styles to choose from</li>
                <li>Get the quality and detail you need</li>
              </ul>
            </div>
            <div className={styles.card}>
              <img src={custom_cheques} alt="Custom cheques Image"></img>
              <Link to="/products/custom-printing/">
                Custom Cheques
              </Link>
              <ul>
                <li>Add customer’s company name, logo and colours</li>
                <li>Help build their brand and image</li>
              </ul>
            </div>
            <div className={styles.card}>
              <img src={ezshield_cheques} alt="EZShield cheque Image"></img>
              <a
                href="https://businesscfpca.ezshield.com/default.aspx"
                target="_blank"
              >
                EZShield Cheque Fraud Protection (opens in new window)
              </a>
              <ul>
                <li>
                  Customers hit by a verified cheque-fraud event* receive
                  advancement of (up to $25,000) within 72 hours allowing
                  customers to stay focused on their business while their bank
                  investigates and resolves the fraud incident
                </li>
              </ul>
            </div>
            <div className={styles.card}>
              <img
                src={cash_management}
                alt="Cash management products Image"
              ></img>
              <Link to="/products/banking-products/">
                Cash Management Products
              </Link>
              <ul>
                <li>Deposit slips, deposit bags and endorsement stamps</li>
                <li>Help customers simplify cash management and save time</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Layout from "../../../other/layout";
import BusinessCheques from "../../../components/AC-StaticPages/products/BusinessCheques";
import SEO from "../../../other/seo";

export default function businessCheques() {
  return (
    <Layout>
      <SEO title="Business Cheques" />

      <BusinessCheques />
    </Layout>
  );
}
